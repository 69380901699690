import { SagaReturnType, all, call, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { addComment, getComments } from '../../services/timesheet';
import { setFetchingFailure, setFetchingStart, setFetchingSuccess } from '../loading/loading-slice';
import {
  addCommentFailure,
  addCommentStart,
  addCommentSuccess,
  fetchCommentsFailure,
  fetchCommentsStart,
  fetchCommentsSuccess,
} from './comments-slice';

export function* fetchCommentsAsync(action: PayloadAction<Record<string, string>>) {
  yield put(setFetchingStart(action.type));
  try {
    const res: SagaReturnType<typeof getComments> = yield getComments({
      timesheetId: action.payload.id,
      companyId: action.payload.companyId,
      employeeId: action.payload.employeeId,
    });
    const comments = res?.data;

    yield put(fetchCommentsSuccess(comments));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    if (error?.errorCode === 403 || error?.status === 403)
      yield put(fetchCommentsFailure('timesheets.contact_admin_hr'));
    else yield put(fetchCommentsFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* addCommentAsync(
  action: PayloadAction<{ id: string; text: string; companyId?: string; employeeId: string; profileId: string }>
) {
  yield put(setFetchingStart(action.type));
  try {
    const body = {
      text: action.payload.text,
    };
    yield addComment({
      timesheetId: action.payload.id,
      employeeId: action.payload.employeeId,
      companyId: action.payload.companyId,
      body,
    });

    const res: SagaReturnType<typeof getComments> = yield getComments({
      timesheetId: action.payload.id,
      companyId: action.payload.companyId,
      employeeId: action.payload.employeeId,
    });
    const comments = res?.data;

    yield put(addCommentSuccess(comments));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    yield put(addCommentFailure(error.message));
    yield put(setFetchingFailure(action.type));
  }
}

export function* onFetchCommentsStart() {
  yield takeLatest(fetchCommentsStart.type, fetchCommentsAsync);
}

export function* onAddCommentStart() {
  yield takeLatest(addCommentStart.type, addCommentAsync);
}

export function* commentsSagas() {
  yield all([call(onFetchCommentsStart), call(onAddCommentStart)]);
}
