import { toast } from '@dartech/dms-ui';

import { SagaReturnType, all, call, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { editTimesheet, getMonthById, getTimesheetsBadges, setTimesheetStatus } from '../../services/timesheet';
import { setError, setFetchingFailure, setFetchingStart, setFetchingSuccess } from '../loading/loading-slice';
import {
  fetchMonthByIdStart,
  fetchMonthByIdSuccess,
  fetchTimesheetNotificationsStart,
  fetchTimesheetsNotificationsSuccess,
  saveTimesheetStart,
  saveTimesheetSuccess,
  setTimesheetStatusStart,
  setTimesheetStatusSuccess,
} from './timesheets-slice';

export function* fetchMonthByIdAsync(action: PayloadAction<Record<string, any>>) {
  yield put(setFetchingStart(action.type));
  try {
    const response: SagaReturnType<typeof getMonthById> = yield getMonthById({
      timesheetId: action.payload.id,
      companyId: action.payload.companyId,
      employeeId: action.payload.employeeId,
    });
    const month = response?.data;

    yield put(fetchMonthByIdSuccess(month));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    let message = '';
    if (error.response && error.response.status === 500) {
      message = 'Sorry, failed to load month. Please, try again.';
    } else if (error.errorCode === 403) {
      message = action.payload['error403'];
    } else if (error.message?.includes('Cannot read properties')) {
      message = action.payload['errorHR'];
    } else {
      message = error.message;
    }

    toast.error(message, { title: ' ' });
    yield put(setFetchingFailure(action.type));
  }
}

export function* fetchTimesheetNotificationsAsync(action: PayloadAction<Record<string, string>>) {
  yield put(setFetchingStart(action.type));
  try {
    const response: SagaReturnType<typeof getTimesheetsBadges> = yield getTimesheetsBadges();

    yield put(fetchTimesheetsNotificationsSuccess(response));
    yield put(setFetchingSuccess(action.type));
  } catch {
    //
  }
}

export function* saveTimesheetAsync(
  action: PayloadAction<{ id: string; data: any; companyId?: string; employeeId?: string }>
) {
  yield put(setFetchingStart(action.type));
  try {
    const { id, data, companyId, employeeId } = action.payload;

    yield editTimesheet({ timesheetId: id, body: data, companyId, employeeId });
    const res: SagaReturnType<typeof getMonthById> = yield getMonthById({ timesheetId: id, companyId, employeeId });
    const month = res?.data;

    yield put(saveTimesheetSuccess(month));
    yield put(setFetchingSuccess(action.type));
    toast.success(action.payload['success'], { title: ' ' });
  } catch (error) {
    toast.error(action.payload['error'], { title: ' ' });
    yield put(setFetchingFailure(action.type));
  }
}

export function* setTimesheetStatusAsync(
  action: PayloadAction<{ id: string; data: { status: any }; companyId?: string; employeeId?: string }>
) {
  yield put(setFetchingStart(action.type));
  try {
    const { id, data, companyId, employeeId } = action.payload;

    yield setTimesheetStatus({ timesheetId: id, body: data, companyId });
    const res: SagaReturnType<typeof getMonthById> = yield getMonthById({ timesheetId: id, companyId, employeeId });
    const month = res?.data;

    yield put(setTimesheetStatusSuccess(month));
    yield put(setFetchingSuccess(action.type));
  } catch (error) {
    toast.error(error.message);
    yield put(setError({ actionType: action.type, message: error.message }));
    yield put(setFetchingFailure(action.type));
  }
}

export function* onFetchMonthByIdStart() {
  yield takeLatest(fetchMonthByIdStart.type, fetchMonthByIdAsync);
}

export function* onSaveTimesheetStart() {
  yield takeLatest(saveTimesheetStart.type, saveTimesheetAsync);
}

export function* onSetTimesheetStatusStart() {
  yield takeLatest(setTimesheetStatusStart.type, setTimesheetStatusAsync);
}

export function* onFetchTimesheetNotificationsStart() {
  yield takeLatest(fetchTimesheetNotificationsStart.type, fetchTimesheetNotificationsAsync);
}

export function* timesheetSagas() {
  yield all([
    call(onFetchMonthByIdStart),
    call(onSaveTimesheetStart),
    call(onSetTimesheetStatusStart),
    call(onFetchTimesheetNotificationsStart),
  ]);
}
